body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}