/* Color variables */
:root {
    --secondary-color: rgb(237, 33, 36);
    --white: #fff;
    --black: #141414;
    --dark-gray: #484848;
    --light-gray: #ECECEC;
    --dark-blue: #2C3A47;
    --light-blue: rgb(59, 87, 246);
}

/* Font */
@font-face {
    font-family: "Poppins";
    src: url("font/Poppins-Regular.ttf");
}
@font-face {
    font-family: "Poppins";
    src: url("font/Poppins-Thin.ttf");
    font-weight: 200;
}
@font-face {
    font-family: "Poppins";
    src: url("font/Poppins-Light.ttf");
    font-weight: 300;
}
@font-face {
    font-family: "Poppins";
    src: url("font/Poppins-Medium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: "Poppins";
    src: url("font/Poppins-SemiBold.ttf");
    font-weight: 600;
}
@font-face {
    font-family: "Poppins";
    src: url("font/Poppins-Bold.ttf");
    font-weight: 700;
}


/* Bootsrap override */
a {
    color: var(--black);
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
}

a {
    font-size: 0.8rem;
}

/* Main styles */
body {
    font-family: "Poppins";
}

.logo {
    width: 130px;
}

.navigation {
    position: absolute;
    background-color: var(--black);
    width: 100%;
    min-height: 68px;
}

.navigation + div,
#psycle + div {
    padding-top: 77px;
}

.main-outlet {
    margin-top: 77px;
}

.navigation .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}

.list-links {
    list-style: none;
    margin: 0 10px;
    display: flex;
}

.link-nav a{
    color: var(--white);
    margin: 4px 8px;
}

/* test nav */
#psycle.navbar {
    padding: 0;
    background-color: var(--black);
}

#psycle.navbar a {
    color: var(--white);
    font-size: 14px;
    border-bottom: 1px solid var(--black); /*optional for transition */
    transition: border 0.3s ease-in;
}

#psycle.navbar a:hover {
    border-bottom: 1px solid var(--white);
}

.navbar-toggler-icon {
    color: var(--white);
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        justify-content: end;
    }

    #psycle.navbar {
        padding: 0 30px;
    }
}

@media (max-width: 991px) {
    #navbar-nav {
        text-align: center;
    }

    .navbar-collapse.collapse.show {
        margin-bottom: 30px;
    } 
}

@media (max-width: 767.5px) {
    .detalle-compra {
        text-align: center;
    }    
}

.hero-section {
    position: relative;
    width: 100%;
    height: 90vh;
    background-image: url('../images/home1.jpg');
    background-size: cover;
    background-position: center;
  }
  

.hero-section2 {
    height: 90vh;
    background-image: url('../images/nosotros.jpg');
    background-size: cover;
    background-position: center;
}

.footer-section {
    background-color: var(--black);
    padding: 20px 0px;
}

.footer-section .links{
    display: flex;
    justify-content: space-around;
}

.footer-section p {
    color: var(--white);
    padding: 8px 16px;
}

.info-section {
    margin: 32px 12px;
}

.address-info {
    text-align: center;
    margin-bottom: 40px;
}

.img-local {
    width: 100%;
    max-width: 550px;
    padding: 16px;
}

.red-logo-cont {
    display: flex;
    justify-content: center;
}

.red-logo {
    height: 25px;
    width: 25px;
    margin: 6px;

}

/* PACKAGES */
.packages-container {
    margin-bottom: 60px;
}

.package {
    margin: 25px auto;
    border: 1px solid var(--dark-gray);
    border-radius: 5px;
    box-shadow: 0px 0px 7px -2px var(--dark-gray);
    color: var(--dark-gray);
    overflow: hidden;
}


.package-width {
    max-width: 150px;
}

.package .num-clases {
    font-size: 40px;
    font-weight: bold;
    padding: 10px;
}

.package .name {
    font-size: 16px;
}

.package .separator {
    margin: 20px 14px;
}

.package .price {
    font-weight: bold;
    font-size: 15px;
    margin: 10px ;
}

.package .expiration {
    margin: 10px ;
}

.package-intro { 
    font-size: 16px;
    margin: 50px 12px 40px 12px;
}

.package-comprar { 
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
}

.package .package-details {
    margin-bottom: 30px;
}

/* Reservar Clases */
.contenedor-dia {
    border: 2px solid var(--dark-gray);
    background: var(--light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 10px;
    margin-bottom: 30px;
    color: var(--dark-gray);
}

.numero-dia {
    font-weight: 600;
    font-size: 28px;
}

.nombre-dia {
    font-size: 16px;
    text-transform: capitalize;
}

.contenedor-clase {
    cursor: pointer;
    background: var(--light-gray);
    padding: 20px 0;
    margin: 20px 0;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
}

.contenedor-clase.unavailable.ride,
.contenedor-clase.unavailable.train {
    cursor: default;
    background: var(--dark-gray);
    pointer-events: none;
}

.contenedor-clase.ride {
    background: var(--light-blue);
    color: white;
}

.contenedor-clase.train {
    background: rgb(237, 33, 36);
    color: white;
}

.special-text {
    background-color: var(--dark-blue);
    color: var(--white);
    width: 100%;
    padding: 5px 0;
    font-weight: 600;
    margin: 5px 0;
}

.coach-text {
    font-size: 12px;
}

.time-text {
    font-size: 16px;
    font-weight: 600;
}

.dia-clase {
    min-width: 100px;
    width: 14.2%;
}

.calendario {
    margin: 70px 15px;
}

.calendario-clase {
    overflow: scroll;
}

.calendario-header {
    margin: 60px 14px 40px 14px;
}

.calendario-titulo {
    font-weight: bold;
    font-size: 28px;
    margin: 10px;
}

.calendario-texto {
    font-size: 16px;
}


/* PERFIL */
.datos-container {
    border: 1px solid var(--dark-gray);
    margin: 60px 20px;
    padding: 20px;
    min-height: 250px;
}

.datos-text {
    margin-bottom: 8px;
}

.checkout-text {
    font-size: 17px;
    margin-bottom: 8px;
}

/* Login */
.auth-form {
    margin: 80px auto 40px auto;
    padding: 22px;
    border: 1px solid var(--dark-gray);
    border-radius: 5px;
}

/* MIS CLASES */
.mis-clases-container {
    margin: 60px 0;
}

/* DETALLES CLASE */
.detalle-clase p {
    margin-bottom: 20px;
    margin-right: 25px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 990.5px) {
    .detalle-clase {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .detalle-clase p {
        align-items: center;
    }

    .calendario-titulo {
        text-align: center;
    }
}

.bola-coach.bola-roja,
.bola-seat.bola-roja {
    background-color: var(--secondary-color);
}

.train-seats-container .bola-seat{
    background-color: var(--secondary-color);
}

.bola-coach {
    background-color: var(--light-blue);
    color: white;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin: 0px auto 50px auto;
    justify-content: center;
}

.name-coach {
    text-align: center;
}

.bola-seat {
    background-color: var(--light-blue);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    cursor: pointer;
}

.bola-seat.info {
    margin: 10px;
    cursor: default;
}

.bola-seat.ocupado {
    background-color: var(--dark-gray);
    color: white;
    cursor: default;
}

.bola-seat.segunda-fila {
    position: absolute;
    left: 50%;
}

.bola-seat.segunda-fila {
    position: absolute;
    left: 50%;
}

.bola-fila {
    width: 12%;
    position: relative;
    height: 60px;
    margin-bottom: 20px;
    color: white;
}

.texto-bola {
    display: flex;
}

.seats-container {
    margin-bottom: 60px;
}

.train-seats-container {
    margin-bottom: 60px;
}

.train-seats-container .bola-coach {
    background-color: var(--secondary-color);
    color: white;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin: 0px auto 50px auto;
    justify-content: center;
}

.first-row,
.second-row {
    width: 20%;
}

.flex-justify-center,
.train-seats-container .bola-fila {
    display: flex;
    justify-content: center;
}

.train-seats-container .bola-fila {
    width: 20%;
}



/* Sobre psycle */
.sobre-lista {
    list-style: none;
    margin-bottom: 20px;
}

.sobre-lista li {
    margin-bottom: 15px;
}

/* COACHES */
.img-coach {
    width: auto;
    height: 240px;
}

.title-sobre {
    font-size: 50px;
    margin: 0 20px;
}

.texto-linea {
    text-decoration: line-through;
}

.texto-sobre {
    font-size: 16px;
}

.hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.proceso-reserva {
    margin-bottom: 80px;
}

/* FAQ */
.accordion-preguntas {
    margin-bottom: 80px;
    min-height: 600px;
}

.lista-preguntas {
    margin: 14px;
}

/* Footer */
.link-footer {
    cursor: pointer;
}

/* Helper */
.m0a {
    margin: 0 auto;
}

.center {
    text-align: center;
}

.loading-container {
    min-height: 300px;
}

.bold {
    font-weight: bold;
}

.semi-bold {
    font-weight: 600;
}

.error {
    color: #dc3545;
}

.pointer {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.texto-normal {
    font-size: 16px;
}

.link {
    color: var(--bs-link-hover-color);
}

.pagination {
    display: flex;
    justify-content: center;
}

/* spinner */
.loading-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

/* Responsive */
@media (max-width: 991px) {
    .bola-seat.segunda-fila {
        left: 0;
        position: relative;
    }
}

.train-seats-container .bola-coach.responsive {
    display: none;
    margin: 50px auto 0px auto;
}


@media (max-width: 320px) {

}

@media (max-width: 480px) {

}

@media (max-width: 770px) {
    .train-seats-container .bola-coach.responsive {
        display: flex;
    }
    .train-seats-container .bola-coach.desktop {
        visibility: hidden;
    }
}

@media (max-width: 1025px) {

}

